import * as React from 'react';
import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  useRecurly
} from '@recurly/react-recurly';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import './CreditCardForm.scss';
import CreditCard from 'assets/icons/credit-card.svg';
import { RootState } from 'core/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFirstName,
  setLastName,
  setSignupLoading
} from 'features/shared/signup/SignupSlice';
import { useHistory } from 'react-router';
import { displayError } from 'core/services/AlertActions';
import { HKBillingPeriod, HKPlatform } from 'core/constants';
import { hideSubscriptionSummaryModal, setNewCreditCard } from './BillingSlice';
import { renewSubscription } from './BillingActions';
import { gaBtnClickEvent } from 'core/util';
import { useEffect, useState } from 'react';
import { createDigitalAccount } from '../signup/SignupActions';
import {
  requestTokenForFirebaseUserSuccess,
  setAuthEmail,
  setAuthIdToken,
  setError
} from '../login/LoginSlice';
import {
  FirebaseAuthentication,
  GetCurrentUserResult
} from '@capacitor-firebase/authentication';
import { updateUserMe } from 'core/services/UserActions';
import { Capacitor } from '@capacitor/core';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { getApp } from 'firebase/app';
const CreditCardForm: React.FC = () => {
  const {
    firstName,
    lastName,
    email,
    password,
    uid,
    address1,
    city,
    state,
    postal_code,
    access_token,
    zipcodeError,
    malformedAddressError
  } = useSelector((state: RootState) => state.signup);
  const { loading } = useSelector((state: RootState) => state.loading);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { newAddOns, newPlan, newBillingPeriod, promoCode, autoRenew, error } =
    useSelector((state: RootState) => state.billing);
  const { loggedIn } = useSelector((state: RootState) => state.login);
  const [zipCode, setZipCode] = useState<string>('');
  const formRef = React.useRef<HTMLFormElement>(null);
  const recurly = useRecurly();
  const dispatch = useDispatch();
  const history = useHistory();

  const goBack = () => {
    if (loggedIn) {
      gaBtnClickEvent('back_to_renew');
    } else {
      gaBtnClickEvent('back_to_create_account');
    }
    history.goBack();
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    gaBtnClickEvent('submit_payment');
    event.preventDefault();
    if (loggedIn) {
      if (formRef !== null && formRef.current !== null) {
        recurly.token(formRef.current, async (err, token) => {
          if (err) {
            console.log(err);
            dispatch(displayError(err.message));
          } else {
            const cardInfo: any = {
              billing_token_id: token.id
            };
            const body: any = {
              currency: 'USD',
              base_plan_id: newPlan.id,
              addon_ids: newAddOns,
              billing_period:
                newBillingPeriod === HKBillingPeriod.MONTHLY ? 1 : 12,
              billing_token_id: token.id,
              auto_renew: autoRenew
            };
            if (promoCode) {
              body['coupon_code'] = promoCode;
            }
            dispatch(setNewCreditCard(cardInfo));
            if (!!homeId) {
              await dispatch(renewSubscription(homeId, body));
              if (!error) {
                dispatch(hideSubscriptionSummaryModal());
                setTimeout(() => {
                  history.push('/renew/confirmation');
                }, 0);
              } else {
                console.log(error);
                dispatch(displayError(error));
              }
            }
          }
        });
      }
    } else {
      gaBtnClickEvent('submit_sign_up');
      event.preventDefault();
      if (formRef !== null && formRef.current !== null) {
        recurly.token(formRef.current, (err, token) => {
          if (err) {
            console.log(err);
            if (err.message != null) {
              dispatch(displayError(err.message));
            } else {
              dispatch(displayError('Could not use address to create account'));
            }
          } else {
            const body: any = {
              email: email,
              first_name: firstName,
              last_name: lastName,
              postal_code: postal_code,
              address_1: address1,
              address_2: null,
              city: city,
              state: state,
              currency: 'USD',
              base_plan_id: newPlan.id,
              base_plan_type_id: newPlan.type_id,
              addon_ids: newAddOns,
              billing_period:
                newBillingPeriod === HKBillingPeriod.MONTHLY ? 1 : 12,
              term: newBillingPeriod === HKBillingPeriod.MONTHLY ? 1 : 12,
              billing_token_id: token.id,
              auto_renew: autoRenew
            };
            if (promoCode) {
              body['coupon_code'] = promoCode;
            }
            if (!!password) {
              body['password'] = password;
            } else {
              body['uid'] = uid;
            }
            dispatch(createDigitalAccount(body));
          }
        });
      }
    }
  }

  const CompleteTermsAndConditions = () => {
    const body = { terms_and_conditions: true };
    dispatch(updateUserMe(body));
  };

  const getCurrentUser = async (): Promise<GetCurrentUserResult> => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  };

  const getIdToken = async () => {
    const result = await FirebaseAuthentication.getIdToken();
    return result.token;
  };

  const getIdTokenForEmail = (email: string) => {
    dispatch(setAuthEmail(email));
    getIdToken().then((token) => {
      dispatch(setAuthIdToken(token));
    });
  };

  const signInWithPassword = async (email: string, password: string) => {
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
      email: email,
      password: password
    }).catch((error) => {
      dispatch(setError('Sign in failed. Please try again.'));
    });
    if (!!result) {
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      getCurrentUser().then((result) => {
        if (!!result.user) {
          getIdTokenForEmail(result.user.email!);
        }
      });
      await signInWithEmailAndPassword(auth, email, password);
    }
  };

  const checkoutDisabled = () => {
    if (newPlan.name.includes('Full Service')) {
      return zipcodeError || zipCode.length < 5 || address1 === null;
    }
    return zipCode.length < 5 || address1 == null;
  };

  useEffect(() => {
    if (access_token) {
      dispatch(
        requestTokenForFirebaseUserSuccess({ access_token: access_token })
      );
      if (!!password) {
        signInWithPassword(email, password!).then();
      }
      CompleteTermsAndConditions();
      dispatch(setSignupLoading(false));
      history.push(`/checkpoint`);
    }
  }, [dispatch, access_token]);

  function DesktopCreditCardFormView() {
    return (
      <>
        <form
          className="hk-desktop-recurly-form"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <div className="hk-desktop-recurly-form-container">
            <div className="hk-desktop-recurly-credit-card">
              <IonIcon icon={CreditCard} />
              <span>Credit Card</span>
            </div>
            <div className="hk-desktop-recurly-form-inputs hk-desktop-recurly-form-name-row">
              <input
                className="hk-desktop-recurly-form-field hk-desktop-recurly-width hk-desktop-recurly-form-first-name"
                data-recurly="first_name"
                defaultValue={firstName}
                placeholder="First Name"
                onChange={(e) => {
                  dispatch(setFirstName(e.target.value));
                }}
              />
              <input
                className="hk-desktop-recurly-form-field hk-desktop-recurly-width"
                data-recurly="last_name"
                defaultValue={lastName}
                placeholder="Last Name"
                onChange={(e) => {
                  dispatch(setLastName(e.target.value));
                }}
              />
              <input
                className="hk-desktop-recurly-form-country"
                data-recurly="country"
                defaultValue="US"
                type="hidden"
              />
            </div>

            <div className="hk-desktop-recurly-form-expiration-row hk-recurly-width">
              <CardNumberElement
                className="hk-desktop-recurly-form-card-number hk-desktop-recurly-width recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'Card number' }
                }}
              />
              <CardMonthElement
                className="hk-desktop-recurly-form-card-month recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'MM' }
                }}
              />
              <CardYearElement
                className="hk-desktop-recurly-form-card-year recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'YY' }
                }}
              />
              <CardCvvElement
                className="hk-desktop-recurly-form-card-cvv recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'CVV' }
                }}
              />
              <input
                className="hk-desktop-recurly-form-postal-code"
                data-recurly="postal_code"
                placeholder="ZIP"
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
            <div className="hk-desktop-recurly-terms-and-conditions">
              After any free trial(s), credit(s), and/or discount(s), will be
              charged monthly plus applicable taxes on a recurring basis.{' '}
              <b>
                {' '}
                If you do not cancel a service during its free trial period, you
                will be charged. Your subscription will continue until you
                cancel. You can cancel at any time via your HomeKeep account or
                by contacting us. By selecting ‘Submit,’ you agree to the above
                terms.
              </b>
            </div>
          </div>

          <div>
            <div className="hk-desktop-recurly-form-actions">
              <div
                className="hk-desktop-recurly-form-back-button"
                onClick={goBack}
              >
                Back
              </div>
              <IonButton
                className="hk-desktop-recurly-button"
                disabled={checkoutDisabled() || loading}
                expand="block"
                color="primary"
                strong={true}
                size="default"
                type="submit"
              >
                {loading ? <IonSpinner name="dots" /> : 'Checkout and Pay'}
              </IonButton>
            </div>
          </div>
        </form>
      </>
    );
  }

  function MobileCreditCardFormView() {
    return (
      <>
        <form className="hk-recurly-form" onSubmit={handleSubmit} ref={formRef}>
          <div className="hk-recurly-form-container">
            <div className="hk-recurly-credit-card">
              <IonIcon icon={CreditCard} />
              <span>Credit Card</span>
            </div>
            <div className="hk-recurly-form-inputs">
              <input
                className="hk-recurly-form-field"
                data-recurly="first_name"
                defaultValue={firstName}
                placeholder="First Name"
                onChange={(e) => {
                  dispatch(setFirstName(e.target.value));
                }}
              />
              <input
                className="hk-recurly-form-field"
                data-recurly="last_name"
                defaultValue={lastName}
                placeholder="Last Name"
                onChange={(e) => {
                  dispatch(setLastName(e.target.value));
                }}
              />
              <input
                className="hk-recurly-form-field"
                data-recurly="country"
                defaultValue="US"
                type="hidden"
              />
            </div>
            <CardNumberElement
              className="hk-recurly-form-card-number recurly-element-inline"
              style={{
                fontColor: '#1b1c1f',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                placeholder: { content: 'Card number' }
              }}
            />
            <div className="hk-recurly-form-expiration-row">
              <CardMonthElement
                className="hk-recurly-form-card-month recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'MM' }
                }}
              />
              <CardYearElement
                className="hk-recurly-form-card-year recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'YY' }
                }}
              />
              <CardCvvElement
                className="hk-recurly-form-card-cvv recurly-element-inline"
                style={{
                  fontColor: '#1b1c1f',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  placeholder: { content: 'CVV' }
                }}
              />
              <input
                className="hk-recurly-form-postal-code"
                data-recurly="postal_code"
                placeholder="ZIP"
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
            <div className="hk-recurly-terms-and-conditions">
              After any free trial(s), credit(s), and/or discount(s), will be
              charged monthly plus applicable taxes on a recurring basis.
              <b>
                {' '}
                If you do not cancel a service during its free trial period, you
                will be charged. Your subscription will continue until you
                cancel. You can cancel at any time via your HomeKeep account or
                by contacting us. By selecting ‘Submit,’ you agree to the above
                terms.
              </b>
            </div>
          </div>

          <div>
            <div className="hk-recurly-form-actions">
              <IonButton
                className="hk-recurly-button"
                disabled={checkoutDisabled() || loading}
                expand="block"
                color="primary"
                strong={true}
                size="default"
                type="submit"
              >
                {loading ? <IonSpinner name="dots" /> : 'Checkout and Pay'}
              </IonButton>
            </div>
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopCreditCardFormView()
        : MobileCreditCardFormView()}
    </>
  );
};

export default CreditCardForm;
