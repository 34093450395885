import React, {
  useState,
  DragEvent,
  ChangeEvent,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFirebaseDownloadUrl,
  useFirebaseUploadResumable
} from 'core/hooks/useFirebase';
import { RootState } from 'core/store';
import { gaBtnClickEvent } from 'core/util';
import './UploadBox.scss';
import UploadIcon from 'assets/icons/upload.svg';
import ImageIcon from 'assets/icons/image.svg';
import { IonIcon } from '@ionic/react';
import { DocumentBody } from 'core/types';
import { createDocument } from 'features/shared/documents/DocumentsActions';
import { setFilesUploading } from 'features/shared/documents/DocumentsSlice';
import { HKPlatform } from 'core/constants';

type UploadBoxProps = {
  type: string;
};

type FileProgress = {
  file: File;
  progress: number;
};

export type UploadBoxCancelUpload = {
  handleCancelUpload: () => void;
};

const UploadBox = forwardRef<UploadBoxCancelUpload, UploadBoxProps>(
  ({ type }, ref) => {
    const homeId = useSelector(
      (state: RootState) => state.home.currentHome?.id
    );
    const { platformType, isDesktopWidth } = useSelector(
      (state: RootState) => state.platform
    );
    const dispatch = useDispatch();
    const hiddenFileInput = useRef<any>(null);
    const { uploadFileResumable } = useFirebaseUploadResumable();
    const [filesProgress, setFilesProgress] = useState<FileProgress[]>([]);
    const [abortControllers, setAbortControllers] = useState<AbortController[]>(
      []
    );

    // Allows handleCancelUpload to be called from parent
    useImperativeHandle(ref, () => ({
      handleCancelUpload
    }));

    const handleCancelUpload = () => {
      abortControllers.forEach((controller) => controller.abort());
      setFilesProgress([]);
      setFilesUploading(false);
    };

    const handleClick = () => hiddenFileInput.current.click();

    const selectDocuments = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        handleFiles(files);
      }
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        handleFiles(files);
      }
    };

    const handleFiles = async (files: FileList) => {
      dispatch(setFilesUploading(true));
      const fileList = Array.from(files);
      let tasks: Promise<void>[] = [];
      let controllers: AbortController[] = [];

      if (fileList.length > 0) {
        for (const file of fileList) {
          const fileProgress: FileProgress = {
            file,
            progress: 0
          };
          setFilesProgress((files) => [...files, fileProgress]);
        }
      }

      for (const file of files) {
        const abortController = new AbortController();
        controllers.push(abortController);

        const uploadPromise = new Promise<void>((resolve, reject) => {
          try {
            const uploadedDocument = uploadDocument(file, abortController);

            // Track upload progress
            uploadedDocument.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFilesProgress((files) =>
                  files.map((f) => (f.file === file ? { ...f, progress } : f))
                );
              },
              (error) => {
                console.error('Upload Error:', error);
                setFilesProgress((files) =>
                  files.filter((f) => f.file !== file)
                );
                reject(error);
              },
              async () => {
                // Upload completed successfully
                const url = await getFirebaseDownloadUrl(
                  uploadedDocument.snapshot.ref.fullPath
                );
                const documentBody: DocumentBody = {
                  name: file.name.substring(0, file.name.lastIndexOf('.')),
                  type,
                  file_url: url
                };
                if (homeId && documentBody) {
                  await dispatch(createDocument(homeId, documentBody));
                }
                resolve();
              }
            );
          } catch (error) {
            console.error('Error creating document:', error);
            setFilesProgress((files) => files.filter((f) => f.file !== file));
            reject(error);
          }
        });

        tasks.push(uploadPromise);
      }

      setAbortControllers(controllers);

      try {
        await Promise.all(tasks);
      } catch (error) {
        console.error('One or more uploads failed', error);
      } finally {
        dispatch(setFilesUploading(false));
      }
    };

    /**
     * Upload a document to Firebase
     */
    const uploadDocument = (
      document: File,
      abortController: AbortController
    ) => {
      gaBtnClickEvent('upload_document');
      try {
        const uploadedFile = uploadFileResumable(
          homeId!,
          document.name,
          document,
          { signal: abortController.signal }
        );
        return uploadedFile;
      } catch (error) {
        console.error('Error uploading document:', error);
        throw error;
      }
    };

    /**
     * Component to render a list of file upload progress bars.
     */
    const FileUploadContainer: React.FC<any> = ({ filesProgress }) => {
      return (
        <div>
          {filesProgress.map(
            (
              fileProgress: { file: { name: any }; progress: any },
              index: any
            ) => (
              <FileUploadProgress
                key={fileProgress.file.name}
                file={fileProgress.file}
                progress={fileProgress.progress}
              />
            )
          )}
        </div>
      );
    };

    /**
     * Component to display a file upload progress bar
     */
    const FileUploadProgress: React.FC<any> = ({ file, progress }) => {
      return (
        <div className="file-upload-list">
          <div className="file-upload-item">
            <div className="file-info">
              <IonIcon icon={ImageIcon} className="file-icon" />
              <span>{file.name}</span>
            </div>
            {progress === 100 ? (
              <span className="file-complete">Complete</span>
            ) : (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{
                    width: `${progress}%`
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <>
        <div
          className="upload-box"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <IonIcon className="upload-icon" icon={UploadIcon} />
          <p>
            {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
              <>
                <strong>Drag & Drop</strong> or
              </>
            ) : (
              ''
            )}
            <label className="upload-label">
              <strong> Choose file </strong>
            </label>
            to upload
          </p>
          <input
            ref={hiddenFileInput}
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={selectDocuments}
          />
        </div>
        {filesProgress.length > 0 && (
          <FileUploadContainer filesProgress={filesProgress} />
        )}
      </>
    );
  }
);

export default UploadBox;
