import React from 'react';
import './Header.scss';
import Illustration from 'components/Illustrations/Illustration';
import HomeKeepLogo from 'assets/illustrations/HomeKeep-Logo-horizontal-dark.svg';
import MenuIcon from 'assets/icons/menu-signup.svg';
import Close from 'assets/icons/close.svg';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { HKPlatform } from 'core/constants';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonMenuToggle,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel
} from '@ionic/react';

const Header: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const history = useHistory();

  const navigateTo = (route: string) => {
    history.push(route);
  };

  function DesktopHeader() {
    return (
      <div className="hk-header">
        <div className="hk-header-nav">
          <div onClick={() => navigateTo('/login')}>
            <Illustration
              image={HomeKeepLogo}
              className="hk-desktop-nav-header-top-logo"
            />
          </div>
          <div className="hk-header-nav-link-list">
            <a
              className="hk-header-nav-link"
              href="https://homekeep.com/how-it-works/"
            >
              How It Works
            </a>
            <a href="/login" className="hk-header-nav-link">
              Sign In
            </a>
            <a href="/signup/plans" className="hk-header-nav-link get-started">
              Get Started
            </a>
            <div className="hk-header-nav-link partner-links">
              Partners
              <div className="partner-links-content">
                <a href="https://homekeep.com/homebuilders/">Homebuilders</a>
                <a href="https://homekeep.com/realtors/">Realtors</a>
                <a href="https://homekeep.com/maintenance-pros/">
                  Maintenance Pros
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function MobileHeader() {
    return (
      <>
        <IonMenu
          side="end"
          contentId="signup-content"
          className="hk-signup-menu"
        >
          <IonHeader>
            <div className="hk-signup-menu__header">
              <Illustration
                image={HomeKeepLogo}
                className="hk-signup-menu__header-logo"
              />
              <IonMenuToggle>
                <IonIcon
                  className="hk-signup-menu__header-close"
                  color="dark"
                  src={Close}
                ></IonIcon>
              </IonMenuToggle>
            </div>
          </IonHeader>
          <IonContent>
            <div className="hk-signup-menu__list">
              <a
                className="hk-signup-menu__list--link"
                href="https://homekeep.com/how-it-works/"
              >
                How It Works
              </a>
              <a href="/login" className="hk-signup-menu__list--link">
                Sign In
              </a>
              <a href="/signup/plans" className="hk-signup-menu__list--link">
                Get Started
              </a>
              <IonAccordionGroup>
                <IonAccordion value="first">
                  <IonItem className="hk-signup-menu__list--item" slot="header">
                    <IonLabel className="hk-signup-menu__list--accordion">
                      Partners
                    </IonLabel>
                  </IonItem>
                  <div
                    className="hk-signup-menu__list--accordion-content"
                    slot="content"
                  >
                    <a href="https://homekeep.com/homebuilders/">
                      - Homebuilders
                    </a>
                    <a href="https://homekeep.com/realtors/">- Realtors</a>
                    <a href="https://homekeep.com/maintenance-pros/">
                      - Maintenance Pros
                    </a>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </div>
          </IonContent>
        </IonMenu>
        <div className="hk-mobile-header" id="signup-content">
          <IonToolbar color="white">
            <div className="hk-mobile-header__content">
              <Illustration
                image={HomeKeepLogo}
                className="hk-mobile-header__logo"
              />
              <IonMenuToggle>
                <IonIcon
                  className="hk-mobile-header__icon"
                  src={MenuIcon}
                ></IonIcon>
              </IonMenuToggle>
            </div>
          </IonToolbar>
        </div>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopHeader()
        : MobileHeader()}
    </>
  );
};

export default Header;
